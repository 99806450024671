import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'energy-measurements',
    loadChildren: () => import('./pages/energy-measurements/overview/overview.module').then( m => m.OverviewPageModule)
  },
  {
    path: 'energy-measurements/step-one',
    loadChildren: () => import('./pages/energy-measurements/step-one/step-one.module').then( m => m.StepOnePageModule)
  },
  {
    path: 'energy-measurements/step-one/:energyMeasurementId',
    loadChildren: () => import('./pages/energy-measurements/step-one/step-one.module').then( m => m.StepOnePageModule)
  },
  {
    path: 'energy-measurements/step-two/:energyMeasurementId',
    loadChildren: () => import('./pages/energy-measurements/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'energy-measurements/step-two/:energyMeasurementId/themes/new/:themeValue',
    loadChildren: () => import('./pages/energy-measurements/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'energy-measurements/step-two/:energyMeasurementId/themes/:themeId',
    loadChildren: () => import('./pages/energy-measurements/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'energy-measurements/:energyMeasurementId/themes/:themeId/edit',
    loadChildren: () => import('./pages/energy-measurements/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'energy-measurements/step-three/:energyMeasurementId',
    loadChildren: () => import('./pages/energy-measurements/step-three/step-three.module').then( m => m.StepThreePageModule)
  },
  {
    path: 'energy-measurements/step-four/:energyMeasurementId',
    loadChildren: () => import('./pages/energy-measurements/step-four/step-four.module').then( m => m.StepFourPageModule)
  },
  {
    path: 'energy-measurements/:energyMeasurementId/edit',
    loadChildren: () => import('./pages/energy-measurements/step-four/step-four.module').then( m => m.StepFourPageModule)
  },
  {
    path: 'energy-measurements/step-five/:energyMeasurementId',
    loadChildren: () => import('./pages/energy-measurements/step-five/step-five.module').then( m => m.StepFivePageModule)
  },
  {
    path: 'energy-measurements/detail/:energyMeasurementId',
    loadChildren: () => import('./pages/energy-measurements/step-five/step-five.module').then( m => m.StepFivePageModule)
  },
  {
    path: 'improvement-plans',
    loadChildren: () => import('./pages/improvement-plans/overview/overview.module').then( m => m.OverviewPageModule)
  },
  {
    path: 'improvement-plans/detail/:improvementPlanId',
    loadChildren: () => import('./pages/improvement-plans/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'improvement-plans/step-one',
    loadChildren: () => import('./pages/improvement-plans/step-one/step-one.module').then( m => m.StepOnePageModule)
  },
  {
    path: 'improvement-plans/step-one/:improvementPlanId',
    loadChildren: () => import('./pages/improvement-plans/step-one/step-one.module').then( m => m.StepOnePageModule)
  },
  {
    path: 'improvement-plans/:improvementPlanId/edit',
    loadChildren: () => import('./pages/improvement-plans/step-one/step-one.module').then( m => m.StepOnePageModule)
  },
  {
    path: 'improvement-plans/step-two/:improvementPlanId/point/:pointId',
    loadChildren: () => import('./pages/improvement-plans/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'improvement-plans/step-two/:improvementPlanId',
    loadChildren: () => import('./pages/improvement-plans/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'improvement-plans/:improvementPlanId/point/:pointId/edit',
    loadChildren: () => import('./pages/improvement-plans/step-two/step-two.module').then( m => m.StepTwoPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'reset-password/:hash/:email',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'admin/dashboard',
    loadChildren: () => import('./pages/admin/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'admin/organisations',
    loadChildren: () => import('./pages/admin/organisations/organisations.module').then( m => m.OrganisationsPageModule)
  },
  {
    path: 'admin/organisations/select',
    loadChildren: () => import('./pages/admin/organisations/select/select.module').then( m => m.SelectPageModule)
  },
  {
    path: 'admin/organisations/new',
    loadChildren: () => import('./pages/admin/organisations/form/form.module').then( m => m.FormPageModule)
  },
  {
    path: 'admin/organisations/edit/:organisationId',
    loadChildren: () => import('./pages/admin/organisations/form/form.module').then( m => m.FormPageModule)
  },
  {
    path: 'admin/organisations/edit-as-coach/:organisationId',
    loadChildren: () => import('./pages/admin/organisations/edit-as-coach/edit-as-coach.module').then( m => m.EditAsCoachPageModule)
  },
  {
    path: 'admin/organisations/detail',
    loadChildren: () => import('./pages/admin/organisations/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./pages/admin/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'admin/users/new',
    loadChildren: () => import('./pages/admin/users/form/form.module').then( m => m.FormPageModule)
  },
  {
    path: 'admin/users/new-as-coach',
    loadChildren: () => import('./pages/admin/users/edit-as-coach/edit-as-coach.module').then( m => m.EditAsCoachPageModule)
  },
  {
    path: 'admin/users/edit/:userId',
    loadChildren: () => import('./pages/admin/users/form/form.module').then( m => m.FormPageModule)
  },
  {
    path: 'admin/users/edit-as-coach/:userId',
    loadChildren: () => import('./pages/admin/users/edit-as-coach/edit-as-coach.module').then( m => m.EditAsCoachPageModule)
  },
  {
    path: 'admin/users/detail/:userId',
    loadChildren: () => import('./pages/admin/users/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'admin/energy-measurement/:energyMeasurementId',
    loadChildren: () => import('./pages/admin/energy-measurement/energy-measurement.module').then( m => m.EnergyMeasurementPageModule)
  },
  {
    path: 'admin/energy-measurement/:energyMeasurementId/measurement-theme/:measurementThemeId',
    loadChildren: () => import('./pages/admin/energy-measurement/measurement-theme/measurement-theme.module').then( m => m.MeasurementThemePageModule)
  },
  {
    path: 'admin/improvement-plan/:improvementPlanId',
    loadChildren: () => import('./pages/admin/improvement-plan/improvement-plan.module').then( m => m.ImprovementPlanPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
