import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config = {};

  constructor(
	public http: HttpClient,
) { 
	this.config['api_url'] = environment['apiUrl'];
  }
  
  load () {
	var self = this;
	let apiUrl =  this.get('api_url');
	return new Promise(function(resolve, reject) 
	{
		self.http.get(apiUrl + 'config', { withCredentials: true })
		.subscribe(
			data => 
			{
				let response: any = data;	
				for (let key in response.configuration) {
					self.config[key] = response.configuration[key];
				}
				resolve(true);
			},
			error => {
				
			}
		);
	});
  }
  
  get (key) {
  	return this.config[key];
  }
}
