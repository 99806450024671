import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  /*
    Every time you perform a build (e.g. ionic build) that updates your web directory (default: www), you'll need to 
    copy those changes into your native projects:
    $ ionic cap copy
    Note: After making updates to the native portion of the code (such as adding a new plugin), use the sync command:
    $ ionic cap sync
  */

  constructor
  (
    private authService: AuthenticationService
  ) 
  {
    this.authService.getCurrentUser();
  }
  
}
